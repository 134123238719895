enum currency {
  EUR = "€",
  ILS = "₪",
  RUB = "₽",
  USD = "$",
  PHP = "₱"
}

declare let CURRENCY: keyof typeof currency;

export const defaultCurrency: keyof typeof currency = CURRENCY;

export const defaultCurrencySymbol = currency[defaultCurrency];

window.defaultCurrencySymbol = defaultCurrencySymbol;

export interface CurrencyMap {
  [key: string]: string;
}

export default currency;
