import http from "common/http/createHttp";
import Vue from "vue";

import DesktopEntry from "applications/desktop/desktop.entry";
import Desktop from "applications/desktop/desktop.vue";
import i18n from "applications/desktop/i18n";
import router from "applications/desktop/router";
import store from "applications/desktop/store";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import PortalVue from "portal-vue";
import { defaultCurrencySymbol } from "../../common/currency/currency";

Vue.use(PortalVue);

Vue.prototype.$defaultCurrencySymbol = defaultCurrencySymbol;

declare const VUE_APP_CURRENT_GIT_SHA: string;
const APP_VERSION = VUE_APP_CURRENT_GIT_SHA;
if (typeof localStorage.APP_VERSION === "undefined" || localStorage.APP_VERSION === null) {
  localStorage.setItem("APP_VERSION", APP_VERSION);
}

if (localStorage.APP_VERSION !== APP_VERSION) {
  // localStorage.clear();
}

type D = Icon.Default & {
    _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png").default,
  iconUrl: require("leaflet/dist/images/marker-icon.png").default,
  shadowUrl: require("leaflet/dist/images/marker-shadow.png").default
});

DesktopEntry({ store, router, http, i18n });

const vue = new Vue({
  el: "#app",
  i18n,
  render: h => h(Desktop),
  router,
  store
});
